

.header-section{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-container{
    width: 100%;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
}

.header-nav-content{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 25px;
}

.header-nav{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;

    --s: 15px; /* the size on the corner */
    --t: 2px;  /* the thickness of the border */
    --g: 0px; /* the gap between the border and image */
    padding: calc(var(--g) + var(--t));
    outline: var(--t) solid #191919; /* the color here */
    outline-offset: calc(-1*var(--t));
    mask:
        conic-gradient(at var(--s) var(--s),#0000 75%,#000 0)
        0 0/calc(100% - var(--s)) calc(100% - var(--s)),
        linear-gradient(#000 0 0) content-box; 

    font-family: "Orbitron", sans-serif;
}

.header-nav p{
    color: #191919;
    font-size: 16px;
    transition: all 0.3s ease;
}

.header-nav p:hover{
    color: white;
    cursor: pointer;
}


@media screen and (max-width: 768px) {
    .header-section{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .header-container{
        width: 100%;
        max-width: 1280px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px 5%;
    }
    
    .header-nav-content{
        flex: 1;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 20px;
        padding: 20px;
    }


    .header-logo{
        display: none;
    }

    .header-nav{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
    
        --s: 15px; /* the size on the corner */
        --t: 2px;  /* the thickness of the border */
        --g: 0px; /* the gap between the border and image */
        padding: calc(var(--g) + var(--t));
        outline: var(--t) solid white; /* the color here */
        outline-offset: calc(-1*var(--t));
        mask:
            conic-gradient(at var(--s) var(--s),#0000 75%,#000 0)
            0 0/calc(100% - var(--s)) calc(100% - var(--s)),
            linear-gradient(#000 0 0) content-box; 
    
        font-family: "Orbitron", sans-serif;
    }
    
    .header-nav p{
        color: white;
        font-size: 16px;
        transition: all 0.3s ease;
        text-align: center;
    }
    
    .header-nav p:hover{
        color: white;
        cursor: pointer;
    }
}