.features-section{
    font-family: "Orbitron", sans-serif;
    background: #191919;
    display: flex;
    justify-content: center;
    align-items: center;
}

.features-container{
    width: calc(100% - 50px);
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    color: white;
    overflow-x: hidden;
    padding: 0 25px;
}
.features-container-text{
    padding: 0px 0px;
}

.features-grid-row {
    padding-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 0px;
}

.feature-grid-content{

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    position: relative;

    background: #1b1b1b;

    width: 100%; /* Ensure all items take the full width of their grid cell */
    box-sizing: border-box;

    --s: 15px; /* the size on the corner */
    --t: 2px;  /* the thickness of the border */
    --g: 0px; /* the gap between the border and image */
    padding: calc(var(--g) + var(--t));
    outline: var(--t) solid #ffffff; /* the color here */
    outline-offset: calc(-1*var(--t));
    mask:
        conic-gradient(at var(--s) var(--s),#0000 75%,#000 0)
        0 0/calc(100% - var(--s)) calc(100% - var(--s)),
        linear-gradient(#000 0 0) content-box; 
    transition: all 0.3s ease;
    

    z-index: 1;
}
.feature-grid-content:hover{
    outline: var(--t) solid #ffb41f;
    background: #2f2f2f;
}

.feature-img{
    padding-top: 10px;
    width: 50px;
    height: 50px;
}

.feature-title{
    color: #ffb41f;
    font-size: 22px;
    font-weight: 500;
    padding: 0 25px;
    text-align: center;
}

.feature-text{
    color: white;
    padding: 0 25px 10px 25px;
    font-family: "Rajdhani", sans-serif;
}

.features-video-section{
    width: 100%;
    margin-top: 50px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 100%, calc(100% - 50px) 100%, 100% calc(100% - 50px), 100% 0);
}

.features-video{
    border-radius: 16px;
}


@media screen and (max-width: 768px) {
    .features-container{
        width: 90%;
        padding: 0 5%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        color: white;
        overflow-x: hidden;
    }
    .features-container-text{
        width: 100%;
        padding: 0px 0px;
        text-align: center;
    }

    .features-grid-row{
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
}

