.footer-section{
    font-family: "Rajdhani", sans-serif;
    background: #191919;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0 25px 0;
}

.footer-container{
    width: 100%;
    max-width: 1280px;
    display: flex;
    align-items: start;
    justify-content: center;
    color: white;
    overflow-x: hidden;
}