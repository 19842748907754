.steps-section{
    font-family: "Orbitron", sans-serif;
    background: #191919;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
}

.steps-container{
    width: calc(100% - 50px);
    padding: 0 25px;
    max-width: 1280px;
    display: flex;
    align-items: start;
    justify-content: start;
    color: white;
    overflow-x: hidden;
    gap: 25px;
}
.steps-container-text{
    padding: 0px 0px;
}

.steps-list{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.step-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    position: relative;

    background: #1b1b1b;

    width: 100%; /* Ensure all items take the full width of their grid cell */
    box-sizing: border-box;

    --s: 15px; /* the size on the corner */
    --t: 2px;  /* the thickness of the border */
    --g: 0px; /* the gap between the border and image */
    padding: calc(var(--g) + var(--t));
    outline: var(--t) solid #ffffff; /* the color here */
    outline-offset: calc(-1*var(--t));
    mask:
        conic-gradient(at var(--s) var(--s),#0000 75%,#000 0)
        0 0/calc(100% - var(--s)) calc(100% - var(--s)),
        linear-gradient(#000 0 0) content-box; 

    scale: 0.9;

    margin-bottom: 10px;
}

.step-box-inner{
    display: flex;
    flex-direction: column;
    padding: 25px;
    gap: 25px;
    flex: 1;
    width: calc(100% - 50px);
    font-family: "Rajdhani", sans-serif;
}

.active{
    scale: 1;
    background: rgba(255, 180, 31, 1);
    color: #191919;
    font-weight: 600;
    outline: var(--t) solid white;
    margin-bottom: 15px;
}



.steps-connection {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.square {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    background-color: white; /* Adjust color as needed */
}

.line {
    width: 2px; /* Adjust thickness as needed */
    background-color: white; /* Adjust color as needed */
    flex-grow: 1; /* Make the line take up the remaining space */
}

.steps-container-text-mobile{
    display: none;
}

@media screen and (max-width: 768px) {
    .steps-container-text{
        display: none;
    }

    .steps-container-text-mobile{
        display: block;
    }

    .steps-section{
        font-family: "Orbitron", sans-serif;
        background: #191919;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 150px;
    }
    
    .steps-container{
        width: 90%;
        padding: 0 5%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        color: white;
        overflow-x: hidden;
    }
    .steps-container-text-mobile{
        width: 100%;
        padding: 0px 0px 50px 0px;
        text-align: center;
    }
}
