.refund-section{
    background: #191919;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 25px 0 25px
}

.refund-box{
    font-family: "Rajdhani", sans-serif;
    clip-path: polygon(0 0, 0 100%, calc(100% - 20px) 100%, 100% calc(100% - 20px), 100% 0);
    display: block;
    background: #2f2f2f;
    color: white;
    width: calc(100% - 50px);
    max-width: 1280px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    position: relative;
    z-index: 2;
}

@media screen and (max-width: 768px) {

    .refund-section{
        background: #191919;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 150px 5% 0 5%;
    }
    
    .refund-box{
        font-family: "Rajdhani", sans-serif;
        clip-path: polygon(0 0, 0 100%, calc(100% - 20px) 100%, 100% calc(100% - 20px), 100% 0);
        display: block;
        background: #2f2f2f;
        color: white;
        width: 100%;
        max-width: 1280px;
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        position: relative;
        z-index: 2;
    }

}