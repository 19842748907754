

.games-section{
    font-family: "Orbitron", sans-serif;
    background: #191919;
    display: flex;
    justify-content: center;
    align-items: center;
}

.games-container{
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 100px 0 0 0;
    color: white;
    overflow-x: hidden;
}
.games-container-text{
    padding: 0px 25px;
}

.games-list-container{
    height: fit-content;
    overflow: hidden;
    padding: 200px 0px 100px 0;
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: space-evenly;
    width: 100%;
    overflow-x: hidden;
}



  .card {

    --card-height: 300px;
    --card-width: calc(var(--card-height) / 1.5);

    width: var(--card-width);
    height: var(--card-height);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 36px;
    perspective: 2500px;
    border-radius: 16px;
  }
  
  .cover-image {
    width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 16px;
}
  
  .wrapper {
    transition: all 0.5s;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
    border-radius: 16px;
  }
  
  .card:hover .wrapper {
    transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
    box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  }
  
  .wrapper::before,
  .wrapper::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 80px;
    transition: all 0.5s;
    position: absolute;
    left: 0;
    border-radius: 16px;
  }
  .wrapper::before {
    top: 0;
    height: 100%;
    background-image: linear-gradient(
      to top,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%
    );
  }
  .wrapper::after {
    bottom: 0;
    opacity: 1;
    background-image: linear-gradient(
      to bottom,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%
    );
    border-radius: 16px;
  }
  
  .card:hover .wrapper::before,
  .wrapper::after {
    opacity: 1;
  }
  
  .card:hover .wrapper::after {
    height: 120px;
  }
  .title {
    width: 100%;
    transition: transform 0.5s;
  }
  .card:hover .title {
    transform: translate3d(0%, -50px, 100px);
  }
  
  .character {
    width: auto;
    max-height: 400px;
    opacity: 0;
    transition: all 0.5s;
    position: absolute;
    bottom: 0;
    z-index: -1;
    border-radius: 16px;
  }
  
  .card:hover .character {
    opacity: 1;
    transform: translate3d(0%, -10%, 100px);
  }



@media screen and (max-width: 768px) {
  .games-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 100px 5% 0 5%;
    color: white;
    overflow-x: hidden;
  }
  .games-container-text{
      padding: 0px 0px;
      text-align: center;
  }

  .games-list-container{
    height: fit-content;
    overflow: hidden;
    padding: 200px 0px 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 200px;
    justify-content: space-evenly;
    width: 100%;
    overflow-x: hidden;
  }
}