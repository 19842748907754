

.hero-section{
    display: flex;
    height: 100dvh;
    position: relative;
    overflow: hidden;
    font-family: "Orbitron", sans-serif;
}

.hero-left-side{
    display: flex;
    flex: 1;
    background: #191919;
}

.hero-right-side{
    display: flex;
    flex: 1;
    background: #ffb41f;
}

#trooper{
    position: absolute;
    height: 90%;
    width: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-40%);
    z-index: 3;
}

.hero-content-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    flex: 1;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-title{
    font-size: 9vw;
    font-weight: 900;
    position: relative;
    color: white;
}
.hero-title::after {
    content: attr(data-hero-title);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    color: transparent;
    -webkit-text-stroke: 2px white;
}

.hero-content-details{
    position: absolute;
    left: 5%;
    bottom: 15%;
    width: 350px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;

    --s: 15px; /* the size on the corner */
    --t: 2px;  /* the thickness of the border */
    --g: 25px; /* the gap between the border and image */
    padding: calc(var(--g) + var(--t));
    outline: var(--t) solid #ffffff; /* the color here */
    outline-offset: calc(-1*var(--t));
    mask:
        conic-gradient(at var(--s) var(--s),#0000 75%,#000 0)
        0 0/calc(100% - var(--s)) calc(100% - var(--s)),
        linear-gradient(#000 0 0) content-box;  
    z-index: 5;
}

.hero-content-details p{
    color: white;
}

.hero-content-btn{
    padding: 10px 10px;
    
    min-width: 100px;
    background: #ffb41f;
}
.hero-content-btn p{
    color: #191919;
    font-weight: 600;
}
.hero-content-btn:hover{
    cursor: pointer;
}

.hero-content-details-mobile{
    display: none;
}


@media screen and (max-width: 768px) {
    .hero-content-container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100dvh;
        flex: 1;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hero-content-details{
        position: absolute;
        left: 5%;
        bottom: 15%;
        width: 350px;
        text-align: center;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;

        /* From https://css.glass */
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    
        --s: 15px; /* the size on the corner */
        --t: 2px;  /* the thickness of the border */
        --g: 25px; /* the gap between the border and image */
        padding: calc(var(--g) + var(--t));
        outline: var(--t) solid #ffffff; /* the color here */
        outline-offset: calc(-1*var(--t));
        mask:
            conic-gradient(at var(--s) var(--s),#0000 75%,#000 0)
            0 0/calc(100% - var(--s)) calc(100% - var(--s)),
            linear-gradient(#000 0 0) content-box;  
        z-index: 5;
    }
    
    .hero-content-details p{
        color: white;
    }
    
    .hero-content-btn{
        padding: 10px 10px;
        
        min-width: 100px;
        background: #ffb41f;
    }
    .hero-content-btn p{
        color: #191919;
        font-weight: 600;
    }
    .hero-content-btn:hover{
        cursor: pointer;
    }

    .hero-content-details-mobile{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 15%;
        width: calc(90% - 50px);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;

        /* From https://css.glass */
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    
        --s: 15px; /* the size on the corner */
        --t: 2px;  /* the thickness of the border */
        --g: 0px; /* the gap between the border and image */
        padding: calc(var(--g) + var(--t));
        outline: var(--t) solid #ffffff; /* the color here */
        outline-offset: calc(-1*var(--t));
        mask:
            conic-gradient(at var(--s) var(--s),#0000 75%,#000 0)
            0 0/calc(100% - var(--s)) calc(100% - var(--s)),
            linear-gradient(#000 0 0) content-box;  
        z-index: 5;
    }

    .hero-content-details-mobile p{
        color: black;
        font-weight: 500;
    }

    .hero-content-details-mobile-inner{
        width: calc(100% - 50px);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
        padding: 25px;

        /* From https://css.glass */
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        z-index: 6;
    }
}

